<template>
<svg
	 viewBox="0 0 512.001 512.001">
<path fill="#F9AC30" d="M227.117,383.98h-46.308c-16.987,0-30.758,13.771-30.758,30.758v34.25h211.906v-34.25
	c0-16.987-13.771-30.758-30.758-30.758h-46.308H227.117z"/>
<path fill="#DD8D19" d="M491.157,70.23c-15.917-24.944-43.818-38.67-78.545-38.67h-0.902H100.3h-0.902
	c-34.738,0-62.628,13.725-78.545,38.67c-17.196,26.947-17.636,62.691-1.206,98.089c30.197,65.029,125.456,86.713,129.503,87.604
	l9.269,2.045h0.01H353.57h0.01l9.279-2.045c4.037-0.891,99.306-22.575,129.493-87.604C508.783,132.921,508.343,97.177,491.157,70.23
	z M463.822,155.066c-12.1,26.056-40.379,43.671-61.978,53.852c-8.912,4.205-17.395,7.497-24.577,9.992l-242.523,0.01h-0.01
	c-7.193-2.506-15.675-5.798-24.577-10.003c-21.589-10.181-49.878-27.796-61.978-53.852c-11.817-25.448-12.11-50.203-0.807-67.913
	c9.93-15.571,28.415-24.137,52.028-24.137h0.933h311.348h0.933c23.613,0,42.088,8.566,52.028,24.137
	C475.933,104.863,475.639,129.618,463.822,155.066z"/>
<path fill="#F9AC30" d="M278.024,383.98l-0.047-30.532c-0.034-21.96,11.352-42.511,30.284-53.637
	c60.287-35.43,103.444-130.412,103.444-242.04V0H100.297v57.769c0,111.63,43.159,206.615,103.448,242.042
	c18.931,11.125,30.317,31.675,30.284,53.633l-0.045,30.535l25.164,26.053L278.024,383.98z"/>
<g>
	<path fill="#DD8D19" d="M245.486,353.447l-0.021,30.533h-11.481l0.042-30.533c0.031-21.956-11.356-42.507-30.281-53.632
		c-60.29-35.43-103.447-130.415-103.447-242.041V0h81.198v57.774c0,111.626,20.656,206.611,49.501,242.041
		C240.055,310.939,245.507,331.49,245.486,353.447z"/>
	<path fill="#DD8D19" d="M247.916,383.98h-20.797H180.81c-16.987,0-30.758,13.771-30.758,30.758v34.25h67.105v-34.25
		C217.157,397.751,230.928,383.98,247.916,383.98z"/>
</g>
<path fill="#4F5B5E" d="M219.114,432.212h-80.945c-9.652,0-17.476,7.824-17.476,17.476v62.314h270.624v-62.314
	c0-9.652-7.824-17.476-17.476-17.476h-80.945"/>
<path fill="#3B4547" d="M159.138,432.212h-20.97c-9.652,0-17.476,7.824-17.476,17.476v62.314h20.97v-62.314
	C141.664,440.036,149.487,432.212,159.138,432.212z"/>
</svg>
</template>

<script>
  export default {
    name: 'trophy',
  }
</script>
